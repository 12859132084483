/* src/App.css */
.App {
  text-align: left;
  font-family: Arial, sans-serif;
  padding: 10px;
}

.join-chat {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join-chat input {
  margin-bottom: 10px;
}

.chat-container {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.sidebar {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.sidebar h2, .sidebar h3 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 5px 0;
}

.main-chat {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.messages {
  border: 1px solid #ccc;
  padding: 10px;
  height: 300px;
  overflow-y: scroll;
  flex-grow: 1;
}

.message {
  padding: 5px;
  margin: 5px 0;
  border-bottom: 1px solid #ddd;
}

.bot-message {
  color: #007bff; /* Sorubot mesajlarının rengi */
}

.input-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-top: 1px solid #ccc;
}

input {
  padding: 10px;
  width: 100%;
  flex-grow: 1;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  flex-shrink: 0;
}

/* Medium and larger screens */
@media (min-width: 768px) {
  .chat-container {
    flex-direction: row;
  }

  .sidebar {
    width: 200px;
    border-bottom: none;
    border-right: 1px solid #ccc;
    padding: 10px;
  }

  .main-chat {
    flex: 1;
    padding: 10px;
  }

  .input-container {
    padding: 10px;
    border-top: none;
  }
}
